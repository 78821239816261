import React, { useEffect } from 'react';

import { PAGES } from '../../ejs-templates/constants';
import useLocalisation from '../../content/localisation';

import './MuteWarning.scss';
import ReactSVG from 'react-svg';
import { Link, useRouter } from 'react-router5';

const MuteWarning = () => {
	const router = useRouter();
	const [ t ] = useLocalisation();
	useEffect(() => {
		const t = setTimeout(() => router.navigate(PAGES.STORY_CHOICE.label), 1500);
		return () => clearTimeout(t);
	}, []);
	return (
		<Link className="mute-warning page" routeName={PAGES.STORY_CHOICE.label}>
			{t('warning_sound')}
			<ReactSVG className="mute-warning__icon" src="./img/icon_unmuted.svg" />
		</Link>
	);
};

export default MuteWarning;
