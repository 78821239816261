import React, { useContext, useEffect, useState } from 'react';

import './ClipProgress.scss';

const ClipProgress = ({ videoRef }) => {
	const [ percentage, setPercentage ] = useState(0);

	useEffect(
		() => {
			if (videoRef.current) {
				const updatePercentage = () => {
					const duration = videoRef.current.duration || 0;
					if (duration !== 0) {
						setPercentage(duration !== 0 ? videoRef.current.currentTime / duration * 100 : 0);
					}
				};
				videoRef.current.addEventListener('timeupdate', updatePercentage);
                return () => videoRef.current && videoRef.current.removeEventListener('timeupdate', updatePercentage);
			}
		},
		[ videoRef.current ],
	);
	return (
		<div className="clip-progress">
			<div className="clip-progress__bar" style={{ width: percentage + '%' }} />
		</div>
	);
};

export default ClipProgress;
