import { useState, useEffect } from 'react';

function getMode() {
    if (typeof window !== 'undefined') {
        return window.innerWidth / window.innerHeight < 1 ? 'portrait' : 'landscape';
    }
}

function useOrientation() {
    let [mode, setMode] = useState(getMode());

    function handleResize() {
        setMode(getMode());
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return mode;
}

export function useWindowSize(callback) {
    function handleResize() {
        callback({width: window.innerWidth, height: window.innerHeight});
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
}


export default useOrientation;
