export const VIDEO_PATH = '<%-videoPath%>';
export const DEBUG = '<%-isDebug-%>';
export const PAGES = {
    START: {
        label: 'start',
        allowPortrait: true,
    },
    WARNING: {
        label: 'warning',
        allowPortrait: true,
    },
    STORY_CHOICE: {
        label: 'stories',
        allowPortrait: false,
    },
    CLIP: {
        label: 'chapter',
        allowPortrait: false,
    },
    FINALE: {
        label: 'laatste keus',
        allowPortrait: false,
    },
};

export const MIME_TYPES_BY_EXT = {
    flv: { name: 'Flash', type: 'video/x-flv' },
    mp4: { name: 'MPEG-4', type: 'video/mp4' },
    m3u8: { name: 'iPhone Index', type: 'application/x-mpegURL' },
    ts: { name: 'iPhone Segment', type: 'video/MP2T' },
    '3gp': { name: '3GP Mobile', type: 'video/3gpp' },
    mov: { name: 'QuickTime', type: 'video/quicktime' },
    avi: { name: 'A/V Interleave', type: 'video/x-msvideo' },
    wmv: { name: 'Windows Media', type: 'video/x-ms-wmv' },
};

export const REWIND_TIME = 30; // 10 seconds

export const SOCIAL_EVENT_LABEL = "https://geboeid.eo.nl/";