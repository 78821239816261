import React, { useContext, useState } from 'react';
import ReactSVG from 'react-svg';
import { animated, useTransition } from 'react-spring/web.cjs';
import fscreen from 'fscreen';

import './Header.scss';
import AppContext from '../../model/ContextStore';
import { PAGES } from '../../model/constants';
import { Link } from 'react-router5';
import useLocalisation from '../../content/localisation';

const Header = ({ isVisible, isLight }) => {
	const { isMuted, toggleMute, setProps } = useContext(AppContext);
	const [ isMaximized, setIsMaximized ] = useState(false);
	const [ , language ] = useLocalisation();
	const transitions = useTransition(isVisible, null, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
	});

	const toggleMaximize = () => {
		if (fscreen.fullscreenEnabled) {
			if (fscreen.fullscreenElement) {
				fscreen.exitFullscreen();
				setIsMaximized(false);
			} else {
				fscreen.requestFullscreen(document.body);
				setIsMaximized(true);
			}
		}
	};

	return transitions.map(
		({ item, key, props }) =>
			item && (
				<animated.div className={`header ${isLight ? 'header--light' : ''}`} style={props} key={key}>
					<ReactSVG
						className="header__menu-button"
						src="./img/icon_menu.svg"
						onClick={() => setProps({ isMenuVisible: true })}
					/>
					<div className="header__spacer" />
					<div className="header__logo">
						<Link
							routeName={PAGES.START.label}
							onClick={() => {
								setProps({ showChoice: false });
							}}
						>
							<img src={`./img/logo_${language}${isLight ? '_white' : ''}.png`} alt="logo" />
						</Link>
					</div>
					{fscreen.fullscreenEnabled ? (
						<ReactSVG
							className="header__fullscreen-button"
							src={`./img/icon_${isMaximized ? 'minimize' : 'maximize'}.svg`}
							onClick={toggleMaximize}
						/>
					) : (<div className="header__spacer" />)}
					<ReactSVG
						className="header__mute-button"
						src={`./img/icon_${isMuted ? '' : 'un'}muted.svg`}
						onClick={toggleMute}
					/>
				</animated.div>
			),
	);
};

export default Header;
