import React, { useContext } from 'react';
import AppContext from '../../model/ContextStore';
import { PAGES } from '../../model/constants';

import './Finale.scss';

const Finale = () => {
    const context = useContext(AppContext);
    return (
        <div
            className="finale bg"
            style={{
                backgroundImage: `url(\'./img/bg_empty.jpg\')`,
            }}
        >
            <h1>Final choice</h1>
            <div className="finale__desc">En nu de laatste keus: Zwart of wit?</div>
            {!!context.finalAnswer ? (
                <>
                    <h2> je koos voor {context.finalAnswer}</h2>
                    <button onClick={() => context.setProps({ page: PAGES.STORY_CHOICE })}>
                       kies een nieuw verhaal
                    </button>
                </>
            ) : (
                <div className="finale__choice">
                    <button
                        className="finale__option"
                        onClick={() => {
                            context.setProps({ finalAnswer: 'zwart' });
                        }}
                    >
                        ZWART
                    </button>
                    <button
                        className="finale__option"
                        onClick={() => {
                            context.setProps({ finalAnswer: 'wit' });
                        }}
                    >
                        WIT
                    </button>
                </div>
            )}
        </div>
    );
};

export default Finale;
