import React, { useContext, useState } from 'react';
import { useTransition, animated, useSprings, useSpring } from 'react-spring/web.cjs';
import fscreen from 'fscreen';

import './PauseMenu.scss';
import AppContext from '../../model/ContextStore';
import useLocalisation from '../../content/localisation';
import ReactSVG from 'react-svg';
import Share from '../gui/Share';
import { useRouter } from 'react-router5';
import Colofon from './Colofon';

const PauseMenu = ({ isVisible, isDark }) => {
	const { setProps } = useContext(AppContext);
	const [ t, language, setLang ] = useLocalisation();
	const transitions = useTransition(isVisible, null, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
	});
	const [ overlayContent, setOverlayContent ] = useState(null);
	const [ showOverlay, setShowOverlay ] = useState(null);
	const items = [
		{
			label: (
				<span>
					{t('menu_language')}{' '}
					<span className="pause-menu__opt">
						<span className={language === 'NL' ? '' : 'pause-menu__strike'}>NL</span>/
						<span className={language === 'NL' ? 'pause-menu__strike' : ''}>EN</span>
					</span>
				</span>
			),
			onClick: () => {
				setLang(language === 'NL' ? 'EN' : 'NL');
				if (language === 'EN') setProps({ showSubtitles: true });
			},
		},
		{
			label: t('menu_verantwoording'),
			onClick: () => {
				setOverlayContent(
					<div style={{ textAlign: 'left', whiteSpace: 'pre-wrap' }}>
						<div className="pause-menu__title">{t('menu_verantwoording')}</div>
						{t('menu_verantwoording_content')}
					</div>,
				);
				setShowOverlay(true);
			},
		},
		{
			label: t('menu_bronnen'),
			onClick: () => {
				setOverlayContent(
					<div style={{ textAlign: 'left', whiteSpace: 'pre-wrap' }}>
						<div className="pause-menu__title">{t('menu_bronnen')}</div>
						<div>{t('menu_bronnen_content')}</div>
						<strong>Mohamed</strong>
						<ul className="pause-menu__bronnen">
							<li>
								Ellen Klinkers (1997), Op hoop van vrijheid. Van slavensamenleving naar Creoolse
								gemeenschap in Suriname, 1830-1880.Proefschrift 1997. Utrecht: Bronnen voor de studie
								van Afro-Surinaamse samenlevingen, deel 18. ISBN 90 393 1288 5. Hieruit: Nachriften aus
								der Brudergemeine 1827;908, 1837;615, 1848;97, 1850;590, 1861;432.
							</li>
							<li>
								A.N. Paasman (1984), Reinhart: Nederlandse literatuur en slavernij ten tijde van de
								Verlichting 158 - 165. Leiden: Nijhoff.
							</li>
						</ul>
						<strong>Betsie</strong>
						<ul>
							<li>
								Verslag vergiftigingszaak Jacquelina van plantage Driesveld, juli 1829 (toegang: 68,
								inventaris: 793). Rotterdamse Gemeente Archief.
							</li>
							<li>
								Hoogbergen, W.S.M., &amp; de Theye, M. (1986). Surinaamse vrouwen in de slavernij
								[Surinamese women in slavery]. Jaarboek voor vrouwengeschiedenis, 7, 126-151.
							</li>
							<li>
								Alex van Stipriaan (1993) Surinaams Contrast. Roofbouw en overleven in een Caraïbische
								plantagekolonie, 1750-1863. Leiden: KITLV, p.412
							</li>
							<li>
								Karwan Fatah-Black (2018), Eigendomsstrijd. De geschiedenis van slavernij en emancipatie
								in Suriname. Amsterdam: Ambo Anthos, p.111.
							</li>
						</ul>
						<strong>Cornelis</strong>
						<ul>
							<li>
								Verslag vergiftigingszaak Jacquelina van plantage Driesveld, juli 1829 (toegang: 68,
								inventaris: 793). Rotterdamse Gemeente Archief.
							</li>
							<li>
								Karwan Fatah-Black (2018) Eigendomsstrijd. De geschiedenis van slavernij en emancipatie
								in Suriname. Amsterdam: Ambo Anthos 2018, blz 111.
							</li>
						</ul>
						<strong>Bonheur</strong>
						<ul>
							<li>
								Karwan Fatah-Black (2018) Eigendomsstrijd. De geschiedenis van slavernij en emancipatie
								in Suriname. Amsterdam: Ambo Anthos 2018, blz 111.
							</li>
							<li>
								Jeroen DeWulf (2018), Grijs slavernijverleden? Over zwarte milities en redimoesoegedrag.
								Amsterdam: Amsterdam University Press, 2018.
							</li>
							<li>
								Silvia de Groot Rebellie der Zwarte Jagers. De nasleep van de Bonni-oorlogen 1788-1809.
								De Gids. Jaargang 133, 9 (1970), blz...
							</li>
							<li>
								Wim Hoogbergen (1985), De Boni-oorlogen, 1757-1860; Marronage en guerrilla in
								Oost-Suriname. Academisch Proefschrift. Bronnen voor de Studie van Afro-Amerikaanse
								Samenlevingen in de Guyana’s, deel 11. Utrecht: Centrum voor Caraïbische Studies.
							</li>
							<li>
								John Gabriel Stedman (1796), Narrative of a Five-years’ Expedition against the Revolted
								Negroes of Surinam, in Guiana on the Wild Coast of South America; from the year 1772, to
								1777. London: J. Johnson and J. Edwards.
							</li>
						</ul>
					</div>,
				);
				setShowOverlay(true);
			},
		},
		{
			label: t('menu_reageren'),
			onClick: () => {
				window.open('https://eo.nl/geboeid', 'reactie');
			},
		},
		{
			label: t('menu_tv'),
			onClick: () => {
				window.open('https://eo.nl/geboeid', 'reactie');
			},
		},
		{
			label: t('menu_vertellers'),
			onClick: () => {
				setOverlayContent(
					<div style={{ textAlign: 'left', whiteSpace: 'pre-wrap' }}>
						{[ 1, 2, 3, 4, 5 ].map((i) => (
							<div className="pause-menu__person">
								<div className="pause-menu__title">{t(`about_${i}_name`)}</div>
								<div>{t(`about_${i}_desc`)}</div>
							</div>
						))}
					</div>,
				);
				setShowOverlay(true);
			},
		},
		{
			label: t('menu_begrippen'),
			onClick: () => {
				setOverlayContent(
					<div style={{ textAlign: 'left', whiteSpace: 'pre-wrap' }}>
						{[ 1, 2, 3, 4, 5, 6, 7 ].map((i) => (
							<div className="pause-menu__person">
								<div className="pause-menu__title">{t(`begrippen_${i}_name`)}</div>
								<div>{t(`begrippen_${i}_desc`)}</div>
							</div>
						))}
					</div>,
				);
				setShowOverlay(true);
			},
		},
		{
			label: t('menu_colofon'),
			onClick: () => {
				setOverlayContent(<Colofon />);
				setShowOverlay(true);
			},
		},
	];
	const subProps = useSprings(
		items.length,
		items.map((item, i) => ({
			delay: i * 100,
			transform: 'translateX(' + (isVisible ? 0 : -50) + 'px)',
			opacity: isVisible ? 1 : 0,
		})),
	);
	const overlaySpring = useSpring(
		showOverlay ? { transform: 'translateX(-100vw)' } : { transform: 'translateX(0vw)' },
	);

	return transitions.map(
		({ item, key, props }) =>
			item && (
				<animated.div className={`pause-menu ${isDark ? 'pause-menu--dark' : ''} fill`} style={props} key={key}>
					<div className="fill" onClick={() => setProps({ isMenuVisible: false })} />
					<animated.div className="pause-menu__page pause-menu__page--center" style={overlaySpring}>
						<div className="pause-menu__items">
							{items.map((item, i) => (
								<animated.div className="pause-menu__button" style={subProps[i]} onClick={item.onClick}>
									{item.label}
								</animated.div>
							))}
							<Share label={t('menu_share')} />
							<ReactSVG
								className="pause-menu__close"
								src="./img/icon_close.svg"
								onClick={() => setProps({ isMenuVisible: false })}
							/>
							<div className="pause-menu__logos">
								<ReactSVG className="pause-menu__logo" src="./img/logo-npostart.svg" />
								<ReactSVG className="pause-menu__logo pause-menu__logo--eo" src="./img/logo-eo.svg" />
								<ReactSVG className="pause-menu__logo" src="./img/logo-hsm.svg" />
							</div>
						</div>
					</animated.div>
					<animated.div className="pause-menu__page" style={overlaySpring}>
						<div className={`pause-menu__overlay ${!showOverlay ? 'pause-menu__overlay--closed' : ''}`}>
							{overlayContent}
							<ReactSVG
								className="pause-menu__close"
								src="./img/icon_close.svg"
								onClick={() => setShowOverlay(false)}
							/>
						</div>
					</animated.div>
				</animated.div>
			),
	);
};

export default PauseMenu;
