import React from 'react';
import useLocalisation from '../../content/localisation';

const COLOFON = [
	[ 'Script and director', 'Script en Regie', 'Hesdy Lonwijk' ],
	[ 'Camera', 'Camera', 'Lukas de Kort' ],
	[ 'Sound', 'Geluid', 'Rene Wolf, Stefan de Groen, Tim van den Broeke' ],
	[ 'Light', 'Licht', 'Joop Deden, Christian van Marle' ],
	[ 'Editing', 'Montage', 'Johannes de Jong' ],
	[ 'Visual design', 'Visual design', 'Sidney Waerts' ],
	[ 'Animation', 'Animatie', 'Manne Heijman' ],
	[ 'Music and sound design', 'Muziek en sounddesign', 'Thomas Kalksma' ],
	[ 'Research and project manager', 'Research en projectleiding', 'Eva Hulscher' ],
	[ 'Scientific advice', 'Wetenschappelijke adviezen', 'Aspha Bijnaar' ],
	[ 'Technical project manager', 'Technische projectleiding', 'Daan Molenaar' ],
	[ 'Frontend developerment', 'Frontend development', 'Daan Heuvingh ' ],
	[ 'Art Director', 'Art Director', 'Roel van Delden' ],
	[ 'Production', 'Productie', 'Wilma Mannee, Jorrit Willems, Jose Duinkerken' ],
	[ 'Concept', 'Concept', 'Gertjan Pool en Daan Molenaar' ],
	[ 'Photography', 'Fotografie', 'Willem-Jan de Bruin' ],
	[ 'Translation', 'Vertaling', 'InVision, Fraukje Heida' ],
	[ 'Editor in chief', 'Eindredactie', 'Alexander Pleizier' ],
	[ 'National Maritime museum', 'Het Scheepvaartmuseum', 'Mascha Dammers, Gundy van Dijk' ],
];
const Colofon = () => {
	const [ t, language ] = useLocalisation();
	return (
		<div className="colofon">
			<table>
				{COLOFON.map((regel) => (
					<tr>
						<td>{regel[language === 'NL' ? 1 : 0]}</td>
						<td>{regel[2]}</td>
					</tr>
				))}
			</table>
			<div style={{ marginTop: '12px' }}>{t('menu_colofon_scheepvaart')}</div>
		</div>
	);
};

export default Colofon;
