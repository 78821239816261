import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTransition, useSpring, animated } from 'react-spring/web.cjs';
import ReactSVG from 'react-svg';
import AppContext from '../../model/ContextStore';

import './Reflection.scss';
import usePlainVideo from '../../hooks/usePlainVideo';
import Subtitle from '../gui/Subtitle';
import Share from '../gui/Share';
import useLocalisation from '../../content/localisation';
import { STORIES_BY_ID } from '../../model/stories';

const ReflectionPopup = ({ showReflection, ...originalProps }) => {
	const reflectionTransitions = useTransition(showReflection, null, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
	});

	return reflectionTransitions.map(
		({ item, key, props }) =>
			item && (
				<animated.div style={props} key={key}>
					<Reflection {...originalProps} />
				</animated.div>
			),
	);
};

const Reflection = ({ action, setIsMaximized }) => {
	const videoRef = useRef(null);
	const appContext = useContext(AppContext);

	const [ isMinimized, setIsMinimized ] = useState(true);
	const [ isPaused, setIsPaused ] = useState(true);
	const [ showShare, setShowShare ] = useState(!isMinimized && !action.autoExpand);
	const [ t ] = useLocalisation();
	setIsMaximized(!isMinimized);

	const maximizeSpring = useSpring({
		// transform: isMinimized ? 'rotate(3deg)' : 'rotate(0deg)',
		right: isMinimized ? '0px' : 0.5 * window.innerWidth - 0.284 * window.innerHeight + 'px',
		bottom: isMinimized ? '30px' : (1 - 0.696) / 2 * window.innerHeight + 'px',
		width: isMinimized ? '98px' : 0.5 * window.innerHeight + 'px',
		height: isMinimized ? '137px' : 0.696 * window.innerHeight + 'px',
		closeOffset: isMinimized ? '1vh' : '6vh',
	});
	const bgSpring = useSpring({
		opacity: isMinimized ? 0 : 0.5,
	});
	const shareProps = useTransition(showShare, null, {
		from: { opacity: 0, transform: 'translateY(100%)' },
		enter: { opacity: 1, transform: 'translateY(0)' },
		leave: {
			opacity: 0,
			transform: 'translateY(100%)',
		},
	});

	useEffect(
		() => {
			if (action.autoExpand) {
				setIsMinimized(false);
				appContext.mainPlayer.pause();
			}
		},
		[ action.autoExpand ],
	);

	useEffect(
		() => {
			if (isMinimized) {
				videoRef.current.pause();
				setIsPaused(true);
				appContext.mainPlayer.play();
			} else {
				appContext.mainPlayer.pause();
			}
		},
		[ isMinimized ],
	);

	const closeReflection = (e) => {
		e.stopPropagation();
		videoRef.current.pause();
		setIsPaused(true);
		setIsMinimized(true);
		setShowShare(false);
        appContext.executor.execute({ type: 'closereflection' });
		if (action.nextAction) {
			appContext.executor.execute(action.nextAction);
		}
	};

	useEffect(
		() => {
			const endCallback = () => {
                if (action.autoExpand) { 
                    setShowShare(!showShare);
                } else {
                    closeReflection();
                }
            }
			if (videoRef.current) {
				videoRef.current.addEventListener('ended',endCallback);
			}
			return () => {
                videoRef.current.removeEventListener('ended', endCallback);
			};
		},
		[ videoRef.current ],
	);

	useEffect(() => {
		if (appContext.showReflectionHint) {
			const timeout = setTimeout(() => {
				appContext.setProps({ showReflectionHint: false });
			}, 4000);
			return () => {
				clearTimeout(timeout);
			};
		}
	}, []);

	usePlainVideo(videoRef, {...action, nextAction: null}, appContext.executor);

    const poster = `./img/${STORIES_BY_ID[appContext.currentStoryId].narator}.jpg`;
	return (
		<animated.div
			style={maximizeSpring}
			className={`reflection ${isMinimized ? '' : 'reflection--maximized'}`}
			onClick={() => {
				// appContext.setProps({ isPaused: isMinimized });
				if (isMinimized) {
					setIsMinimized(false);
					videoRef.current.currentTime = 0;
					videoRef.current.play();
					setIsPaused(false);
				} else {
					if (isPaused) {
						videoRef.current.play();
						setIsPaused(false);
					} else {
						videoRef.current.pause();
						setIsPaused(true);
					}
				}
			}}
		>
			<animated.div className="reflection__lightbox" style={bgSpring} />
			<div className="reflection__phone fill">
				<div className="reflection__large">
					<video
						ref={videoRef}
						className="reflection__video"
						playsInline
                        poster={poster}
                        muted={appContext.isMuted}
					/>
				</div>
				<ReactSVG className="reflection__background fill" src="./img/phone.svg" />
				{!isMinimized && <Subtitle videoRef={videoRef} name={action.clip.split('/')[0]} />}
				{isPaused && (
					<ReactSVG
						className="reflection__play"
						src="./img/icon_play.svg"
						onClick={
							isMinimized ? (
								() => setIsMinimized(false)
							) : (
								() => {
									videoRef.current.play();
									setIsPaused(false);
								}
							)
						}
					/>
				)}
				<animated.div
					className="reflection__close"
					onClick={closeReflection}
					style={{ right: maximizeSpring.closeOffset }}
				>
					<ReactSVG src="./img/icon_close.svg" />
				</animated.div>
			</div>
			{shareProps.map(
				({ item, key, props }) =>
					item && (
						<animated.div className="reflection__share" key={key} style={props}>
							<Share label={t('menu_share')} />
						</animated.div>
					),
			)}
			{appContext.showReflectionHint && (
				<div className="reflection__info">
					{t('reflectie_info').replace(
						'[]',
						t(`stories_${STORIES_BY_ID[appContext.currentStoryId].narator}`),
					)}
				</div>
			)}
		</animated.div>
	);
};

export default ReflectionPopup;
