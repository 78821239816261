import React, { useContext, useState, useEffect } from 'react';
import AppContext from '../model/ContextStore';

function getUrlParameter(name) {
	name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
	var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
	var results = regex.exec(location.search);
	return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export const LocalisationContext = React.createContext();
export const LocalisationContextProvider = ({ children, initialLanguage, initialTexts }) => {
	const [ state, setState ] = useState({ language: initialLanguage, texts: initialTexts });
	const switchLanguage = (lang) => {
		setState((state) => ({ ...state, language: lang }));
		localStorage.setItem('appLanguage', lang);
    };
    const {setProps} = useContext(AppContext);
	useEffect(() => {
		const urlLang = getUrlParameter('language').toUpperCase();
        const savedLang = localStorage.getItem('appLanguage');
		if (urlLang === 'NL' || urlLang === 'EN') {
            switchLanguage(urlLang);
		} else if (savedLang) {
            switchLanguage(savedLang);
        } 
        if (!savedLang && urlLang === 'EN') {
            setProps({showSubtitles: true});
		}
	}, []);
	return <LocalisationContext.Provider value={{ ...state, switchLanguage }}>{children}</LocalisationContext.Provider>;
};

export default function useLocalisation() {
	const context = useContext(LocalisationContext);
	const t = (key) => context.texts[context.language][key] || `[${key}]`;
	return [ t, context.language, context.switchLanguage ];
}
