import React, { useEffect, useReducer } from 'react';
import { PAGES } from './constants';
import executor from './StoryExecutor';
import { STORIES_PER_ACTOR, STORIES_BY_ID } from '../model/stories';
import { useRouter, useRouteNode } from 'react-router5';

export const AppContext = React.createContext();

export const getActionWithReflectionTriggers = (action, storyId) => {
	const reflectionTriggers = action.reflections
		? action.reflections.reduce((res, r, i) => {
				res.push({
					time: r[0],
					action: { type: 'reflect', clip: `${storyId}_reflectie_${i + 1}/playlist.m3u8` },
				});
				if (r[1] !== null) res.push({ time: r[1], action: { type: 'closereflection' } });
				return res;
			}, [])
		: [];
	return {
		...action,
		triggers: reflectionTriggers,
	};
};
function reducer(state, action) {
	let s = state;
	switch (action.type) {
		case 'setstate':
			s = {
				...state,
				...action.state,
			};
			break;
		case 'log':
			console.log('reducer.dispatch => LOG:', action.text);
			return state;
		case 'setvar':
			s = {
				...state,
				[action.key]: action.value,
			};
			break;
		case 'clip':
			s = {
				...state,
				pageAction: getActionWithReflectionTriggers(action, state.currentStoryId),
				showChoice: false,
				showReflection: false,
			};
			break;
		case 'choice':
			s = {
				...state,
				//isPaused: true,
				currentChoice: {
					...action,
					question: `${state.currentStoryId}_choice_question`,
					options: action.options.map((o, i) => ({
						...o,
						label: `${state.currentStoryId}_choice_answer_${i}`,
					})),
				},
				showChoice: true,
			};
			break;
		case 'reflect':
			s = {
				...state,
				currentReflection: action,
				showReflection: true,
			};
			break;
		case 'closereflection':
			s = {
				...state,
				showReflection: false,
			};
			break;
		// case 'startstory':
		// 	const id = typeof action.story === 'string' ? action.story : action.story.id;
		// 	s = {
		// 		...state,
		// 		currentStoryId: id,
		// 		showReflection: false,
		// 	};
		// 	break;
		case 'finishstory':
			s = {
				...state,
				watchedStories: {
					...state.watchedStories,
					[state.currentStoryId]: true,
				},
			};
			break;
		case 'resetstories':
			s = {
				...state,
				finalAnswer: initialState['finalAnswer'],
				watchedStories: initialState['watchedStories'],
				page: PAGES.STORY_CHOICE,
			};
			break;
		default:
			console.log(`unknown action ${action ? action.type : 'no action'}`);
			return state;
	}
	if (state.isHydrated) {
		saveState(s);
	}
	return s;
}

const HAS_LOCAL_STORAGE = typeof localStorage !== 'undefined';
const SAVE_VARS = [
	'showSubtitles',
    'watchedStories',
    'isMuted',
];
function saveState(state) {
	if (HAS_LOCAL_STORAGE) {
		const res = SAVE_VARS.reduce((r, v) => {
			r[v] = state[v];
			return r;
		}, {});
		localStorage.setItem('appState', JSON.stringify(res));
	}
}
function loadState() {
	if (HAS_LOCAL_STORAGE) {
		const savedStateString = localStorage.getItem('appState');
		if (savedStateString) {
			const savedState = JSON.parse(savedStateString);
			return {
				...savedState,
			};
		}
	}
	return {};
}

const initialState = {
	isHydrated: false,
	page: PAGES.START,
	pageAction: null,
	mainPlayer: null,
	isPaused: false,
	isMenuVisible: false,
    isHeaderVisible: false,
    isMuted: true,
	executor,
	currentStoryId: null,
	watchedStories: {},
	finalAnswer: null,
	currentChoice: null,
	showChoice: false,
	language: 'NL',
	currentReflection: null,
	showReflection: false,
	showSubtitles: false,
	showReflectionHint: true,
};

function getStateFromRoute(route) {
	let result = {
		page: Object.values(PAGES).find((page) => page.label === route.name) || PAGES.START,
	};
	if (route.name === PAGES.CLIP.label) {
		const story = STORIES_BY_ID[route.params.storyId];
		const action = story.actions[route.params.clipId];
		executor.actions = story.actions;
		result = {
			...result,
			currentStoryId: story.id,
			showReflection: null,

			showChoice: false,
			pageAction: getActionWithReflectionTriggers(action, story.id),
		};
	}
	//	console.log('ContextStore -> getStateFromRoute', route.name, result);
	return result;
}

export const AppContextProvider = ({ children }) => {
	const router = useRouter();
	const { route } = useRouteNode('');
	const [ appState, dispatch ] = useReducer(reducer, {
		...initialState,
		// ...(getStateFromRoute(route) || {}), // TODO: prevent re-calculation on every rerender
	});

	executor.setState(appState, dispatch, router);
	useEffect(() => {
		const savedState = loadState();
		dispatch({ type: 'setstate', state: { ...savedState, isHydrated: true } });
	}, []);

	useEffect(
		() => {
            console.log('ContextStore -> changed route',route);
			const newState = getStateFromRoute(route);
			if (newState.currentStoryId && newState.currentStoryId !== appState.currentStoryId) {
				const story = STORIES_BY_ID[newState.currentStoryId];
				appState.executor.actions = story.actions;
				appState.executor.story = story;
			}
			dispatch({
				type: 'setstate',
				state: {
					...newState,
					showReflection: false,
					showChoice: false,
				},
			});
		},
		[ route ],
	);

	return (
		<AppContext.Provider
			value={{
				...appState,
				setProps: (state) => dispatch({ type: 'setstate', state }),

				gotoPage: (page) => dispatch({ type: 'setstate', state: { page } }),
				startStory: (story) => {
					// appState.executor.execute({ type: 'startstory', story });
					// appState.executor.startStory(story);
					router.navigate('chapter', { storyId: story.id, clipId: story.startAction });
				},
				reset: () => dispatch({ type: 'resetstories' }),
				toggleMute: () => {
					const isMuted = !appState.isMuted;
					const videos = [ ...document.getElementsByTagName('video') ];
					videos.forEach((video) => {
						video.muted = isMuted;
					});
					dispatch({ type: 'setstate', state: { isMuted } });
				},
				unPause: () => {
					dispatch({
						type: 'setstate',
						state: {
							isMenuVisible: false,
						},
					});
				},
			}}
		>
			{children}
		</AppContext.Provider>
	);
};

export default AppContext;
