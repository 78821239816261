import React, {useState} from 'react';

export const PlayerContext = React.createContext();

export const PlayerContextProvider = ({children}) => {

    const [player, setPlayer] = useState();
    const [resolution, setResolution] = useState({width: 1, height: 1});

    return (
        <PlayerContext.Provider value={{
            player, setPlayer,
            resolution, setResolution,
        }}>
            {children}
        </PlayerContext.Provider>
    );
};

export default PlayerContextProvider;

