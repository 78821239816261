import React, { useEffect, useState } from 'react';

const usePlayingState = (videoRef) => {
	const [ isPlaying, setIsPlaying ] = useState(false);
	useEffect(
		() => {
			const enable = () => setIsPlaying(true);
			const disable = () => setIsPlaying(false);
			videoRef.current.addEventListener('playing', enable);
			videoRef.current.addEventListener('pause', disable);
			videoRef.current.addEventListener('ended', disable);
			return () => {
				videoRef.current.removeEventListener('playing', enable);
				videoRef.current.removeEventListener('pause', disable);
				videoRef.current.removeEventListener('ended', disable);
			};
		},
		[ videoRef.current, setIsPlaying ],
	);
	return isPlaying;
};

export default usePlayingState;
