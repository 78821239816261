import React, { useContext } from 'react';
import { useTransition, animated } from 'react-spring/web.cjs';

import AppContext from '../../model/ContextStore';
import { PAGES } from '../../model/constants';
import StoryChoice from '../story-choice/StoryChoice';
import Instructions from '../instructions/Instructions';
import PauseMenu from '../menu/PauseMenu';
import Choice from '../overlays/Choice';
import StoryLinks from '../storylinks/StoryLinks';
import Clip from '../story/Clip';
import Header from '../menu/Header';

import './AppContent.scss';
import Finale from '../finalchoice/Finale';
import MuteWarning from '../instructions/MuteWarning';
import { useRouteNode } from 'react-router5';
// import PortraitWarning from '../gui/PortraitWarning';

const PAGE_COMPONENTS = {
	[PAGES.START.label]: Instructions,
	[PAGES.WARNING.label]: MuteWarning,
	[PAGES.STORY_CHOICE.label]: StoryChoice,
	[PAGES.CLIP.label]: Clip,
	[PAGES.FINALE.label]: Finale,
};

const BLACK = '#0D0F15';
const WHITE = '#FFFDF8';

const PAGE_BG = {
	[PAGES.START.label]: WHITE,
	[PAGES.WARNING.label]: WHITE,
	[PAGES.STORY_CHOICE.label]: WHITE,
	[PAGES.CLIP.label]: BLACK,
};

const PageNotFound = ({ page }) => (
	<div className="page-not-found">
		Page {page.label} <i>not</i> found
	</div>
);

const AppContent = () => {
	const { route } = useRouteNode('');
	const { page, isMenuVisible } = useContext(AppContext);

	const items = [ route.name ];
	const transitions = useTransition(items, (item) => item, {
		from: { opacity: '0' },
		enter: { opacity: '1' },
		leave: { opacity: '0.8' },
	});

	return (
		<div className="app-content">
			{transitions.map(({ item, key, props }) => {
				const PageComponent = PAGE_COMPONENTS[item] || PageNotFound;
				return (
					<animated.div
						className={`app-content__transition-wrapper app-content__transition-wrapper--${item}`}
						key={key}
						style={{
							...props,
							backgroundColor: [ PAGE_BG[item] ],
						}}
					>
						{ PAGE_BG[item] !== BLACK && (
							<React.Fragment>
								<div
									className="app-content__left"
									style={{ backgroundImage: "url('./img/landing-bg-left.png')" }}
								/>

								<div
									className="app-content__right"
									style={{ backgroundImage: "url('./img/landing-bg-right.png')" }}
								/>
							</React.Fragment>
						)}
						<PageComponent />
					</animated.div>
				);
			})}
			<Header isVisible={page.label !== PAGES.CLIP.label} />
			<Choice />
			<PauseMenu isVisible={isMenuVisible} isDark={page.label !== PAGES.CLIP.label} />
			{/* <PortraitWarning /> */}
		</div>
	);
};

export default AppContent;
