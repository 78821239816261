
const STORIES = [
  {
    "id": "bonheur_6",
    "narator": "jorgen",
    "actor": "bonheur",
    "startAction": "dilemma",
    "actions": {
      "dilemma": {
        "progress": 0,
        "type": "clip",
        "clip": "bonheur_6/playlist.m3u8",
        "nextAction": "conclusie",
        "id": "dilemma"
      },
      "conclusie": {
        "type": "reflect",
        "clip": "bonheur_eindreflectie/playlist.m3u8",
        "autoExpand": true,
        "nextAction": {
          "type": "finishstory"
        },
        "id": "conclusie"
      }
    }
  },
  {
    "id": "mohamed_5",
    "narator": "defano",
    "actor": "mohamed",
    "startAction": "dilemma",
    "actions": {
      "dilemma": {
        "progress": 0,
        "type": "clip",
        "clip": "mohamed_5/playlist.m3u8",
        "reflections": [
          [
            9,
            19
          ],
          [
            33,
            43
          ]
        ],
        "nextAction": "conclusie",
        "id": "dilemma"
      },
      "conclusie": {
        "type": "reflect",
        "clip": "mohamed_eindreflectie/playlist.m3u8",
        "autoExpand": true,
        "nextAction": {
          "type": "finishstory"
        },
        "id": "conclusie"
      }
    }
  },
  {
    "id": "mohamed_4",
    "narator": "defano",
    "actor": "mohamed",
    "startAction": "dilemma",
    "actions": {
      "dilemma": {
        "progress": 0,
        "type": "clip",
        "clip": "mohamed_4/playlist.m3u8",
        "nextAction": {
          "type": "choice",
          "default": "random",
          "timer": 10,
          "options": [
            {
              "action": "gevolg"
            },
            {
              "action": {
                "type": "startstory",
                "story": "mohamed_5"
              }
            }
          ]
        },
        "id": "dilemma"
      },
      "gevolg": {
        "progress": 1,
        "type": "clip",
        "clip": "mohamed_4a/playlist.m3u8",
        "nextAction": {
          "type": "startstory",
          "story": "mohamed_5"
        },
        "id": "gevolg"
      }
    }
  },
  {
    "id": "mohamed_3",
    "narator": "defano",
    "actor": "mohamed",
    "startAction": "dilemma",
    "actions": {
      "dilemma": {
        "progress": 0,
        "type": "clip",
        "clip": "mohamed_3/playlist.m3u8",
        "reflections": [
          [
            19,
            29
          ]
        ],
        "nextAction": {
          "type": "choice",
          "default": "random",
          "options": [
            {
              "action": {
                "type": "startstory",
                "story": "mohamed_4"
              }
            },
            {
              "action": "gevolg"
            }
          ]
        },
        "id": "dilemma"
      },
      "gevolg": {
        "progress": 1,
        "type": "clip",
        "clip": "mohamed_3a/playlist.m3u8",
        "nextAction": {
          "type": "startstory",
          "story": "mohamed_4"
        },
        "id": "gevolg"
      }
    }
  },
  {
    "id": "mohamed_2",
    "narator": "defano",
    "actor": "mohamed",
    "startAction": "dilemma",
    "actions": {
      "dilemma": {
        "progress": 0,
        "type": "clip",
        "clip": "mohamed_2/playlist.m3u8",
        "reflections": [
          [
            5,
            11
          ],
          [
            22,
            35
          ]
        ],
        "nextAction": {
          "type": "choice",
          "default": "random",
          "options": [
            {
              "action": {
                "type": "startstory",
                "story": "mohamed_3"
              }
            },
            {
              "action": "gevolg"
            }
          ]
        },
        "id": "dilemma"
      },
      "gevolg": {
        "progress": 1,
        "type": "clip",
        "clip": "mohamed_2a/playlist.m3u8",
        "nextAction": {
          "type": "startstory",
          "story": "mohamed_3"
        },
        "id": "gevolg"
      }
    }
  },
  {
    "id": "mohamed_1",
    "narator": "defano",
    "actor": "mohamed",
    "startAction": "dilemma",
    "actions": {
      "dilemma": {
        "progress": 0,
        "type": "clip",
        "clip": "mohamed_1/playlist.m3u8",
        "nextAction": {
          "type": "choice",
          "default": "random",
          "options": [
            {
              "action": "gevolg"
            },
            {
              "action": {
                "type": "startstory",
                "story": "mohamed_2"
              }
            }
          ]
        },
        "id": "dilemma"
      },
      "gevolg": {
        "progress": 1,
        "type": "clip",
        "clip": "mohamed_1a/playlist.m3u8",
        "nextAction": {
          "type": "startstory",
          "story": "mohamed_2"
        },
        "id": "gevolg"
      }
    }
  },
  {
    "id": "cornelis_3",
    "narator": "frans",
    "actor": "cornelis",
    "startAction": "dilemma",
    "actions": {
      "dilemma": {
        "progress": 0,
        "type": "clip",
        "clip": "cornelis_3/playlist.m3u8",
        "nextAction": {
          "type": "choice",
          "default": "random",
          "options": [
            {
              "action": "gevolg"
            },
            {
              "action": {
                "type": "startstory",
                "story": "cornelis_4"
              }
            }
          ]
        },
        "id": "dilemma"
      },
      "gevolg": {
        "progress": 1,
        "type": "clip",
        "clip": "cornelis_3a/playlist.m3u8",
        "nextAction": {
          "type": "startstory",
          "story": "cornelis_4"
        },
        "id": "gevolg"
      }
    }
  },
  {
    "id": "betsie_1",
    "narator": "dolores",
    "actor": "betsie",
    "startAction": "dilemma",
    "actions": {
      "dilemma": {
        "progress": 0,
        "type": "clip",
        "clip": "betsie_1/playlist.m3u8",
        "reflections": [
          [
            45,
            58
          ],
          [
            96,
            null
          ]
        ],
        "nextAction": {
          "type": "choice",
          "default": "random",
          "options": [
            {
              "action": {
                "type": "startstory",
                "story": "betsie_2"
              }
            },
            {
              "action": "gevolg"
            }
          ]
        },
        "id": "dilemma"
      },
      "gevolg": {
        "progress": 1,
        "type": "clip",
        "clip": "betsie_1a/playlist.m3u8",
        "nextAction": {
          "type": "startstory",
          "story": "betsie_2"
        },
        "id": "gevolg"
      }
    }
  },
  {
    "id": "betsie_3",
    "narator": "dolores",
    "actor": "betsie",
    "startAction": "dilemma",
    "actions": {
      "dilemma": {
        "type": "clip",
        "clip": "betsie_3/playlist.m3u8",
        "progress": 0,
        "nextAction": {
          "type": "choice",
          "default": "random",
          "options": [
            {
              "action": "gevolg"
            },
            {
              "action": {
                "type": "startstory",
                "story": "betsie_4"
              }
            }
          ]
        },
        "id": "dilemma"
      },
      "gevolg": {
        "type": "clip",
        "clip": "betsie_3a/playlist.m3u8",
        "progress": 1,
        "nextAction": {
          "type": "startstory",
          "story": "betsie_4"
        },
        "id": "gevolg"
      }
    }
  },
  {
    "id": "betsie_4",
    "narator": "dolores",
    "actor": "betsie",
    "startAction": "dilemma",
    "actions": {
      "dilemma": {
        "type": "clip",
        "clip": "betsie_4/playlist.m3u8",
        "progress": 0,
        "reflections": [
          [
            128,
            140
          ],
          [
            187,
            null
          ]
        ],
        "nextAction": {
          "type": "choice",
          "default": "random",
          "options": [
            {
              "action": "gevolg"
            },
            {
              "action": {
                "type": "startstory",
                "story": "betsie_5"
              }
            }
          ]
        },
        "id": "dilemma"
      },
      "gevolg": {
        "type": "clip",
        "clip": "betsie_4a/playlist.m3u8",
        "progress": 1,
        "nextAction": {
          "type": "startstory",
          "story": "betsie_5"
        },
        "id": "gevolg"
      }
    }
  },
  {
    "id": "betsie_2",
    "narator": "dolores",
    "actor": "betsie",
    "startAction": "dilemma",
    "actions": {
      "dilemma": {
        "type": "clip",
        "clip": "betsie_2/playlist.m3u8",
        "progress": 0,
        "reflections": [
          [
            12,
            25
          ],
          [
            143,
            158
          ],
          [
            160,
            171
          ],
          [
            218,
            null
          ]
        ],
        "nextAction": {
          "type": "choice",
          "default": "random",
          "options": [
            {
              "action": "gevolg"
            },
            {
              "action": {
                "type": "startstory",
                "story": "betsie_3"
              }
            }
          ]
        },
        "id": "dilemma"
      },
      "gevolg": {
        "type": "clip",
        "clip": "betsie_2a/playlist.m3u8",
        "progress": 1,
        "nextAction": {
          "type": "startstory",
          "story": "betsie_3"
        },
        "id": "gevolg"
      }
    }
  },
  {
    "id": "betsie_5",
    "narator": "dolores",
    "actor": "betsie",
    "startAction": "dilemma",
    "actions": {
      "dilemma": {
        "type": "clip",
        "clip": "betsie_5/playlist.m3u8",
        "progress": 0,
        "nextAction": "conclusie",
        "id": "dilemma"
      },
      "conclusie": {
        "type": "reflect",
        "clip": "betsie_eindreflectie/playlist.m3u8",
        "autoExpand": true,
        "nextAction": {
          "type": "finishstory"
        },
        "id": "conclusie"
      }
    }
  },
  {
    "id": "cornelis_2",
    "narator": "frans",
    "actor": "cornelis",
    "startAction": "dilemma",
    "actions": {
      "dilemma": {
        "progress": 0,
        "type": "clip",
        "clip": "cornelis_2/playlist.m3u8",
        "reflections": [
          [
            3,
            13
          ],
          [
            167,
            177
          ]
        ],
        "nextAction": {
          "type": "choice",
          "default": "random",
          "options": [
            {
              "action": {
                "type": "startstory",
                "story": "cornelis_3"
              }
            },
            {
              "action": "gevolg"
            }
          ]
        },
        "id": "dilemma"
      },
      "gevolg": {
        "progress": 1,
        "type": "clip",
        "clip": "cornelis_2a/playlist.m3u8",
        "nextAction": {
          "type": "startstory",
          "story": "cornelis_3"
        },
        "id": "gevolg"
      }
    }
  },
  {
    "id": "cornelis_4",
    "narator": "frans",
    "actor": "cornelis",
    "startAction": "dilemma",
    "actions": {
      "dilemma": {
        "progress": 0,
        "type": "clip",
        "clip": "cornelis_4/playlist.m3u8",
        "reflections": [
          [
            3,
            13
          ],
          [
            158,
            168
          ]
        ],
        "nextAction": {
          "type": "choice",
          "default": "random",
          "timer": 10,
          "options": [
            {
              "action": "gevolg"
            },
            {
              "action": {
                "type": "startstory",
                "story": "cornelis_5"
              }
            }
          ]
        },
        "id": "dilemma"
      },
      "gevolg": {
        "progress": 1,
        "type": "clip",
        "clip": "cornelis_4a/playlist.m3u8",
        "nextAction": {
          "type": "startstory",
          "story": "cornelis_5"
        },
        "id": "gevolg"
      }
    }
  },
  {
    "id": "cornelis_5",
    "narator": "frans",
    "actor": "cornelis",
    "startAction": "dilemma",
    "actions": {
      "dilemma": {
        "progress": 0,
        "type": "clip",
        "clip": "cornelis_5/playlist.m3u8",
        "reflections": [
          [
            83,
            93
          ]
        ],
        "nextAction": "conclusie",
        "id": "dilemma"
      },
      "conclusie": {
        "type": "reflect",
        "clip": "cornelis_eindreflectie/playlist.m3u8",
        "autoExpand": true,
        "nextAction": {
          "type": "finishstory"
        },
        "id": "conclusie"
      }
    }
  },
  {
    "id": "cornelis_1",
    "narator": "frans",
    "actor": "cornelis",
    "startAction": "dilemma",
    "actions": {
      "dilemma": {
        "progress": 0,
        "type": "clip",
        "clip": "cornelis_1/playlist.m3u8",
        "nextAction": {
          "type": "choice",
          "default": "random",
          "options": [
            {
              "action": {
                "type": "startstory",
                "story": "cornelis_2"
              }
            },
            {
              "action": "gevolg"
            }
          ]
        },
        "id": "dilemma"
      },
      "gevolg": {
        "progress": 1,
        "type": "clip",
        "clip": "cornelis_1a/playlist.m3u8",
        "nextAction": {
          "type": "startstory",
          "story": "cornelis_2"
        },
        "id": "gevolg"
      }
    }
  },
  {
    "id": "bonheur_3",
    "narator": "jorgen",
    "actor": "bonheur",
    "startAction": "dilemma",
    "actions": {
      "dilemma": {
        "progress": 0,
        "type": "clip",
        "clip": "bonheur_3/playlist.m3u8",
        "reflections": [
          [
            73,
            87
          ],
          [
            126,
            156
          ],
          [
            197,
            306
          ]
        ],
        "nextAction": {
          "type": "choice",
          "default": "random",
          "options": [
            {
              "action": {
                "type": "startstory",
                "story": "bonheur_4"
              }
            },
            {
              "action": "gevolg"
            }
          ]
        },
        "id": "dilemma"
      },
      "gevolg": {
        "progress": 1,
        "type": "clip",
        "clip": "bonheur_3a/playlist.m3u8",
        "nextAction": {
          "type": "startstory",
          "story": "bonheur_4"
        },
        "id": "gevolg"
      }
    }
  },
  {
    "id": "bonheur_1",
    "narator": "jorgen",
    "actor": "bonheur",
    "startAction": "dilemma",
    "actions": {
      "dilemma": {
        "progress": 0,
        "type": "clip",
        "clip": "bonheur_1/playlist.m3u8",
        "nextAction": {
          "type": "choice",
          "default": "random",
          "timer": 10,
          "options": [
            {
              "action": "gevolg"
            },
            {
              "action": {
                "type": "startstory",
                "story": "bonheur_2"
              }
            }
          ]
        },
        "id": "dilemma"
      },
      "gevolg": {
        "progress": 1,
        "type": "clip",
        "clip": "bonheur_1a/playlist.m3u8",
        "nextAction": {
          "type": "startstory",
          "story": "bonheur_2"
        },
        "id": "gevolg"
      }
    }
  },
  {
    "id": "bonheur_5",
    "narator": "jorgen",
    "actor": "bonheur",
    "startAction": "dilemma",
    "actions": {
      "dilemma": {
        "progress": 0,
        "type": "clip",
        "clip": "bonheur_5/playlist.m3u8",
        "reflections": [
          [
            10,
            20
          ],
          [
            95,
            106
          ],
          [
            260,
            null
          ]
        ],
        "nextAction": {
          "type": "choice",
          "default": "random",
          "options": [
            {
              "action": "gevolg"
            },
            {
              "action": {
                "type": "startstory",
                "story": "bonheur_6"
              }
            }
          ]
        },
        "id": "dilemma"
      },
      "gevolg": {
        "progress": 1,
        "type": "clip",
        "clip": "bonheur_5a/playlist.m3u8",
        "nextAction": {
          "type": "startstory",
          "story": "bonheur_6"
        },
        "id": "gevolg"
      }
    }
  },
  {
    "id": "bonheur_4",
    "narator": "jorgen",
    "actor": "bonheur",
    "startAction": "dilemma",
    "actions": {
      "dilemma": {
        "progress": 0,
        "type": "clip",
        "clip": "bonheur_4/playlist.m3u8",
        "reflections": [
          [
            3,
            15
          ],
          [
            82,
            92
          ]
        ],
        "nextAction": {
          "type": "choice",
          "default": "random",
          "timer": 10,
          "options": [
            {
              "action": {
                "type": "startstory",
                "story": "bonheur_5"
              }
            },
            {
              "action": "gevolg"
            }
          ]
        },
        "id": "dilemma"
      },
      "gevolg": {
        "progress": 1,
        "type": "clip",
        "clip": "bonheur_4a/playlist.m3u8",
        "nextAction": {
          "type": "startstory",
          "story": "bonheur_5"
        },
        "id": "gevolg"
      }
    }
  },
  {
    "id": "bonheur_2",
    "narator": "jorgen",
    "actor": "bonheur",
    "startAction": "dilemma",
    "actions": {
      "dilemma": {
        "progress": 0,
        "type": "clip",
        "clip": "bonheur_2/playlist.m3u8",
        "reflections": [
          [
            9,
            21
          ],
          [
            44,
            54
          ],
          [
            73,
            85
          ]
        ],
        "nextAction": {
          "type": "choice",
          "default": "random",
          "options": [
            {
              "action": "gevolg"
            },
            {
              "action": {
                "type": "startstory",
                "story": "bonheur_3"
              }
            }
          ]
        },
        "id": "dilemma"
      },
      "gevolg": {
        "progress": 1,
        "type": "clip",
        "clip": "bonheur_2a/playlist.m3u8",
        "nextAction": {
          "type": "startstory",
          "story": "bonheur_3"
        },
        "id": "gevolg"
      }
    }
  }
];
export const STORIES_PER_ACTOR = {"betsie":["betsie_1","betsie_2","betsie_3","betsie_4","betsie_5"],"bonheur":["bonheur_1","bonheur_2","bonheur_3","bonheur_4","bonheur_5","bonheur_6"],"cornelis":["cornelis_1","cornelis_2","cornelis_3","cornelis_4","cornelis_5"],"mohamed":["mohamed_1","mohamed_2","mohamed_3","mohamed_4","mohamed_5"]};
export const STORIES_BY_ID = STORIES.reduce((r,s) => {r[s.id] = s;return r;},{});
export default STORIES