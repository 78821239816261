import React, { useMemo } from 'react';

import './Tile.scss';
import { animated, useSpring } from 'react-spring/web.cjs';
import useLocalisation from '../../content/localisation';
import usePlainVideo from '../../hooks/usePlainVideo';

const Tile = ({ i, story, actorVidRef, activatedIndex, selectedIndex, clickActor, start, isStarted, sizeProp }) => {
	const fadeOutSpring = useSpring({
		opacity: activatedIndex === null ? 1 : 0,
		delay: activatedIndex === i ? 500 : 0,
		onRest: activatedIndex === i && !isStarted.current ? start : null,
	});
	const [ t ] = useLocalisation();

	const action = useMemo(
		() => ({
			type: 'clip',
			clip: `${story.actor}_intro/playlist.m3u8`,
		}),
		[ story ],
	);
	usePlainVideo(actorVidRef, action, null);
	const narator = typeof story === 'undefined' ? '' : story.narator;

	return (
		<animated.div
			style={fadeOutSpring}
			className={`tile  ${selectedIndex === i ? 'tile--selected' : ''} ${selectedIndex !== i &&
			selectedIndex !== null
				? 'tile--unselected'
				: ''}`}
			onClick={() => clickActor(i)}
		>
			<div className="tile__thumbnail">
				{/* <div className="tile__square"> */}
				<div
					className="tile__mugshot bg"
					alt={narator}
					style={{
						...sizeProp,
						backgroundImage: `url(\'./img/${narator}.jpg\')`,
					}}
				/>
				<div
					className="tile__greyed bg"
					alt={narator + 'greyed out'}
					style={{
						...sizeProp,
						backgroundImage: `url(\'./img/${narator}_zw.jpg\')`,
					}}
				/>
				{/* </div> */}
			</div>
			<div className="tile__narator">{t(`stories_${narator}`)}</div>
			<div className="tile__intro-wrapper">
				<video className="tile__video" ref={actorVidRef} playsInline={true} style={sizeProp} loop />

				<div className="tile__begin" style={{ backgroundImage: "url('./img/beginverhaal-knop.png')" }}>
					{t('stories_begin')}
				</div>
			</div>
		</animated.div>
	);
};

export default Tile;
