import React, { useContext, useEffect, useRef, useState } from 'react';

import AppContext from '../../model/ContextStore';
import PlayerContextProvider, { PlayerContext } from '../../model/PlayerContext';
import Pause from './Pause';
import Reflection from './Reflection';

import './Clip.scss';
import usePlainVideo from '../../hooks/usePlainVideo';
import Header from '../menu/Header';
import Subtitle from '../gui/Subtitle';
import usePlayingState from '../../hooks/usePlayingState';
import { DEBUG } from '../../model/constants';
import useVisibility from '../../hooks/useVisibility';
import ClipProgress from '../gui/ClipProgress';

const ClipButtons = ({ buttons = [] }) => {
	const { executor } = useContext(AppContext);

	return (
		<React.Fragment>
			{buttons.map(
				(item, i) =>
					item && (
						<button
							key={item.label}
							style={{
								position: 'absolute',
								left: 0,
								top: i * 40 + 400,
								background: '#CCC',
								padding: '8px',
								cursor: 'pointer',
								width: '100px',
								textAlign: 'left',
							}}
							onClick={() => executor.execute(item.action)}
						>
							{item.label}
						</button>
					),
			)}
		</React.Fragment>
	);
};

const Clip = () => {
	const videoRef = useRef(null);
	const {
		pageAction: action,
		executor,
		setProps,
		unPause,
		showReflection,
		currentReflection,
        showChoice,
        isMuted,
	} = useContext(AppContext);
	const [ isMaximized, setIsMaximized ] = useState(false);

	const updateClip = usePlainVideo(videoRef, action, executor);
	const isPlaying = usePlayingState(videoRef);

	useVisibility(() => {
		videoRef.current && videoRef.current.pause();
	});

	useEffect(
		() => {
			setProps({ mainPlayer: videoRef.current });
			setProps({ updateClip });
			return () => setProps({ mainPlayer: null });
		},
		[ videoRef.current ],
	);

	const buttons = [
		// {label: 'naar einde', action: {type:'seek', time: 110}},
		// {label: 'reflectie', action: {type:'reflect', clip:'betsie_1_reflectie_1/playlist.m3u8'}},
		// {label: 'close refl', action: {type:'closereflection'}}
		/*{
			label: 'choice',
			action: {
				type: 'choice',
				default: 'random',
                timer: 100,
				options: [
					{
						action: {
							type: 'startstory',
							story: 'betsie_2',
						},
					},
					{
                        action: {
                            type: 'startstory',
                            story: 'betsie_3',
                        },
					},
				],
			},
		},*/
    ];
	return (
		<div className="clip">
			<div className="clip__video">
				<video
					onClick={() => {
						videoRef.current.pause();
					}}
					ref={videoRef}
					className="clip__player"
					playsInline={true}
                    muted={isMuted}
				/>
				{isPlaying && <ClipProgress videoRef={videoRef} />}
				{action && <Subtitle videoRef={videoRef} name={action.clip.split('/')[0]} />}
			</div>
			{isPlaying && DEBUG && <ClipButtons buttons={buttons} />}
			<Reflection
				action={currentReflection}
				showReflection={showReflection && !showChoice}
				setIsMaximized={setIsMaximized}
				isMaximized={isMaximized}
			/>
			{action && (
				<Pause
					playerRef={videoRef}
					isVisible={!isPlaying && !showChoice && !isMaximized}
				/>
			)}
			<Header isVisible={!isPlaying && !showChoice && !isMaximized} isLight={true} />
		</div>
	);
};

export default () => (
	<PlayerContextProvider>
		<Clip />
	</PlayerContextProvider>
);
