import { useRef, useEffect, useContext } from 'react';
import AppContext from '../model/ContextStore';
import { VIDEO_PATH } from '../model/constants';
import useVisibility from './useVisibility';
import Hls from 'hls.js';

const HLS_CONFIG = {
	capLevelToPlayerSize: true,
};
const BASE_RE = /https?:\/\/[^\/\?]*/g;
const BASE_URL = typeof document !== 'undefined' && document.location.href.match(BASE_RE)[0];
const BASE_PATH = VIDEO_PATH.indexOf('http') === 0 ? VIDEO_PATH : BASE_URL + VIDEO_PATH;

function updateClip(player, hls, action, lastUrl, executor) {
	let triggers = action && action.triggers;

    const triggerNext = () => {
		// console.log('usePlainVideo -> NEXT UP', action);
		executor.execute(action.nextAction);
	};

	const checkTriggers = () => {
		const newTime = player.currentTime;
		const lastTime = player._lastTime;
		let triggers = action && action.triggers;
		if (triggers && triggers.length > 0) {
			triggers.forEach((trigger) => {
				let { time } = trigger;
				if (time < 0) {
					time = player.duration + time;
				}
				if (time > lastTime && time < newTime) {
					// console.log('usePlainVideo -> TRIGGER', trigger.action, 'from', action);
					executor.execute(trigger.action);
				}
			});
		}
		player._lastTime = newTime;
	};

    
	if (player && action) {
        const url = BASE_PATH + action.clip;
        // console.log('usePlainVideo -> URL',lastUrl.current,'  ->  ',action.clip, action.id);
		if (url !== lastUrl.current) {
			lastUrl.current = url;
			const ext = url.substring(url.lastIndexOf('.') + 1);
			if (ext !== 'm3u8' || player.canPlayType('application/vnd.apple.mpegurl')) {
				//* If HLS is natively supported, let the browser do the work!
				player.src = url;
			} else if (Hls.isSupported()) {
                if(hls.current) {
                    hls.current.detachMedia();
                }
				if (!hls.current) {
					hls.current = new Hls({
						...HLS_CONFIG,
						startLevel: parseInt(localStorage.getItem('hlsLevel') | '2'),
					});
				}
                hls.current.attachMedia(player);
				hls.current.on(Hls.Events.MEDIA_ATTACHED, function() {
                    // console.log('usePlainVideo -> LOADING',url);
                    hls.current.loadSource(url);
                });
				hls.current.on(Hls.Events.LEVEL_SWITCHED, (eventLabel, { level }) => {
					localStorage.setItem('hlsLevel', level);
                });
                hls.current.on(Hls.Events.ERROR, function(event, data) {
					if (data.fatal) {
						switch (data.type) {
							case Hls.ErrorTypes.NETWORK_ERROR:
								console.log('fatal network error encountered, try to recover.');
								hls.current.startLoad();
								break;
							case Hls.ErrorTypes.MEDIA_ERROR:
								console.log('fatal media error encountered, try to recover.');
								hls.current.recoverMediaError();
								break;
							default:
                                console.warn('fatal unrecoverable video error encountered.')
								hls.current.destroy();
								break;
						}
					}
				});

			} else {
			}
			player._lastTime = 0;
			player.currentTime = 0;
		}

		player.addEventListener('ended', triggerNext);
		if (triggers) player.addEventListener('timeupdate', checkTriggers);
		return () => {
			// console.log('usePlainVideo ->','REMOVE eventlisteners');
			player.removeEventListener('ended', triggerNext);
            player.removeEventListener('timeupdate', checkTriggers);
            // if (hls.current) {hls.current.destroy()}
		};
	}
	return Function.prototype;
}

function handleVisibilityChanges(isVisible, player) {
	if (player && !isVisible) {
		player.pause();
	}
}

const usePlainVideo = (videoRef, action, executor) => {
	const player = videoRef.current;
	const hls = useRef();
	const lastUrl = useRef('');
	const cleanup = useRef(Function.prototype);

	useEffect(
		() => {
			// console.log('usePlainVideo ->','UPDATE via useEffect');
			cleanup.current();
			cleanup.current = updateClip(player, hls, action, lastUrl, executor);
		},
		[ player, action, executor ],
	);
	useVisibility((isVisible) => {
		handleVisibilityChanges(isVisible, videoRef.current);
	});

	useEffect(() => {
		return () => cleanup.current();
	}, []);

	return (newAction) => {
		// console.log('usePlainVideo ->','UPDATE directly');
		cleanup.current();
		cleanup.current = updateClip(player, hls, newAction, lastUrl, executor);
	};
};

export default usePlainVideo;
