import React, {Component} from 'react';

import './RadialProgress.scss';

class RadialProgress extends Component {
    render() {
        const { size = 100, progress = 0, strokeWidth = size * 0.1, circleRef } = this.props;

        const center = size / 2;
        const radius = size / 2 - strokeWidth / 2;
        const circumference = 2 * Math.PI * radius;
        const offset = (1 - progress) * circumference;
        const style = {
            strokeDashoffset: offset,
        };

        return (
            <svg className="radial-progress" width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
                <circle
                    className="radial-progress__background"
                    cx={center}
                    cy={center}
                    r={radius}
                    strokeWidth={strokeWidth - 1}
                />
                <circle
                    ref={circleRef}
                    className="radial-progress__fill"
                    style={style}
                    cx={center}
                    cy={center}
                    r={radius}
                    strokeWidth={strokeWidth}
                    strokeDasharray={circumference}
                />
            </svg>
        );
    }
}

export default RadialProgress;
