import createRouter from 'router5';
import browserPlugin from 'router5-plugin-browser';

function configureRouter() {
	const router = createRouter(
		[
            { name: 'start', path: '/start' },
            { name: 'warning', path: '/warning'},
			{ name: 'stories', path: '/stories' },
			{ name: 'chapter', path: '/chapter/:storyId/:clipId' },
		],
		{
			trailingSlash: true,
            defaultRoute: 'start',
            queryParamsMode: 'loose',
		},
	);
	router.usePlugin(
		browserPlugin({
			useHash: false,
		}),
	);

	return router;
}

export default configureRouter;
