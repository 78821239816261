import React, { useContext } from 'react';
import { useTransition, animated, useSpring, useSprings } from 'react-spring/web.cjs';
import RadialProgress from '../gui/RadialProgress';
import AppContext from '../../model/ContextStore';
import { STORIES_BY_ID, STORIES_PER_ACTOR } from '../../model/stories';

import './StoryLinks.scss';

const StoryLink = ({ isDefault, link }) => {
    const { startStory } = useContext(AppContext);
    return (
        <div
            className={`story-links__link ${isDefault ? 'story-links__link--default' : ''}`}
            onClick={() => {
                startStory(STORIES_BY_ID[link.story]);
            }}
        >
            {link.label}
        </div>
    );
};

const TIMER_TIME = 14 * 1000;
const AnimatedRadialProgress = animated(RadialProgress);

const StoryLinks = () => {
    const { currentStoryId, startStory } = useContext(AppContext);
    const { next, related = [] } = currentStoryId && STORIES_BY_ID[currentStoryId].links;
    const allLinks = [next, ...related];
    const usedactors = allLinks.map(l => {
        return STORIES_BY_ID[l.story].actor;
    });
    const unusedActors = Object.keys(STORIES_PER_ACTOR).filter(a => usedactors.indexOf(a) === -1);
    const unusedStories = unusedActors.map(a => STORIES_BY_ID[STORIES_PER_ACTOR[a][0]]);

    const timerProps = !!next
        ? useSpring({
              to: { value: 1 },
              from: { value: 0 },
              config: { duration: TIMER_TIME },
              onRest: () => {
                  !!next && startStory(STORIES_BY_ID[next.story]);
              },
          })
        : null;

    return (
        <div className="story-links fill">
            {timerProps && (
                <div className="story-links__timer">
                    <AnimatedRadialProgress size={100} strokeWidth={20} progress={timerProps.value} />
                </div>
            )}

            {!!next && (
                <div className="story-links__group">
                    <StoryLink link={next} isDefault={true} />
                </div>
            )}
            {!!related && (
                <div className="story-links__group">
                    {related.map(link => (
                        <StoryLink key={link.story} link={link} />
                    ))}
                </div>
            )}
            {unusedStories.length !== 0 && (
                <div className="story-links__group">
                    {unusedStories.map(story => (
                        <StoryLink
                            key={story.id}
                            link={{ story: story.id, label: `Wil je liever ${story.actor} volgen? Klik hier!` }}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default StoryLinks;
