import React, { useState } from 'react';
import ReactSVG from 'react-svg';
import useLocalisation from '../../content/localisation';

import './Chain.scss';

const Chain = ({ stepsTotal, currentStep, stepProgress, onClickStep }) => {
    const [t] = useLocalisation();
    const [clickedStep, setClickedStep] = useState(null);
    
    const getStepPercentage = (step) => ((step + stepProgress) * 100) / (stepsTotal + 0.2) + '%';
    
    return (
        <div className="chains">
            <ReactSVG className="chains__chainsbg" src={`./img/${stepsTotal}storychains.svg`} />
            <div className="chains__chainsfg fill" style={{width: getStepPercentage(currentStep, stepsTotal)}}>
                <ReactSVG src={`./img/${stepsTotal}storychains.svg`} />
            </div>
            {clickedStep === null ? (
                <div className="chains__step" style={{ left: getStepPercentage(currentStep, stepsTotal) }}>
                    {t('pause_story')} {currentStep + 1}/{stepsTotal}
                </div>
            ) : (
                <div
                    className="chains__confirm"
                    style={{ left: getStepPercentage(clickedStep, stepsTotal) }}
                    onClick={() => onClickStep(clickedStep)}
                >
                    {t('pause_go')} {clickedStep + 1}
                </div>
            )}
            <div
                className="chains__chapters fill"
                onClick={e => {
                    const rect = e.currentTarget.getBoundingClientRect();
                    const x = (((e.pageX - rect.left) / rect.width) * (stepsTotal)) | 0;
                    setClickedStep(x !== currentStep && x < stepsTotal ? x : null);
                }}
            />
        </div>
    );
};

export default Chain;
