import React, { useContext, useState, useEffect, useRef } from 'react';
import { useTransition, animated, useSpring, useSprings } from 'react-spring/web.cjs';

import AppContext from '../../model/ContextStore';

import './Choice.scss';
import RadialProgress from '../gui/RadialProgress';
import Header from '../menu/Header';
import { useWindowSize } from '../../hooks/useOrientation';
import useLocalisation from '../../content/localisation';
import { STORIES_BY_ID } from '../../model/stories';

const AnimatedRadialProgress = animated(RadialProgress);

const HEIGHT = 120;
const distY = HEIGHT + 4 + 1;
const distX = 360 + 8 + 1;
const directionProps = {
	down: {
		eventProp: 'pageY',
		transform: 'translateY',
		targetY: distY,
		checkY: (dy) => dy > distY * 0.6,
		minY: 0,
		maxY: distY,
	},
	up: {
		eventProp: 'pageY',
		transform: 'translateY',
		targetY: -distY,
		checkY: (dy) => dy < -distY * 0.6,
		minY: -distY,
		maxY: 0,
	},
	right: {
		eventProp: 'pageX',
		transform: 'translateX',
		targetY: distX,
		checkY: (dy) => dy > distX * 0.6,
		minY: 0,
		maxY: distX,
	},
	left: {
		eventProp: 'pageX',
		transform: 'translateX',
		targetY: -distX,
		checkY: (dy) => dy < -distX * 0.6,
		minY: -distX,
		maxY: 0,
	},
};
const Option = ({ text, isSelected, isDiscarded, onSelect, direction, onHover }) => {
	const startY = useRef(null);
	const lastY = useRef(null);
	const [ isDragging, setIsDragging ] = useState(false);
	const [ choiceProps, setChoiceProps ] = useSpring(() => ({ y: 0 }));
	const dir = directionProps[direction];

	useEffect(
		() => {
			setChoiceProps({ y: isSelected ? dir.targetY : 0 });
		},
		[ isSelected ],
	);

	const onMouseDone = () => {
		if (isDragging) {
			const isSelected = dir.checkY(lastY.current - startY.current);
			onSelect(isSelected);
			setChoiceProps({ y: isSelected ? dir.targetY : 0 });
			setIsDragging(false);
		}
	};

	const onStartDrag = (e) => {
		const y = e[dir.eventProp] - choiceProps.y.value;
		startY.current = y;
		lastY.current = y;
		setIsDragging(true);
	};
	const onMove = (e) => {
		if (isDragging) {
			lastY.current = e[dir.eventProp];
			const y = lastY.current - startY.current;
			onHover(dir.checkY(y));
			setChoiceProps({ y, immediate: true });
		}
	};
	return (
		<animated.div
			className={`choice__option ${isDragging ? 'choice__option--dragging' : ''} ${isSelected
				? 'choice__option--selected'
				: ''} ${isDiscarded ? 'choice__option--discarded' : ''}`}
			style={{
				transform: choiceProps.y.interpolate(
					(y) =>
						`${dir.transform}(${Math.min(dir.maxY, Math.max(dir.minY, y))}px) ${isDragging
							? 'perspective(600px) translate3d(0,0,40px) rotate3d(1,0.6,0,-8deg)'
							: ''}`,
				),
			}}
			onMouseDown={onStartDrag}
			onMouseMove={onMove}
			onMouseUp={onMouseDone}
			// onMouseLeave={onMouseDone}
			onTouchStart={(e) => onStartDrag(e.touches[0])}
			onTouchMove={(e) => onMove(e.touches[0])}
			onTouchCancel={(e) => onMouseDone(e.touches[0])}
			onTouchEnd={(e) => onMouseDone(e.touches[0])}
		>
			{text}
		</animated.div>
	);
};

function getOrientation(width, height) {
	return width > height && width >= 1120 ? 'landscape' : 'portrait';
}
const CHOICE_DIRECTIONS = {
	landscape: [ 'right', 'left' ],
	portrait: [ 'down', 'up' ],
};
const Choice = () => {
	const context = useContext(AppContext);
	const choice = context.currentChoice;
	const hasChosenOnce = useRef();
	const [ selectedOption, setSelectedOption ] = useState(null);
	const [ activatedOption, setActivatedOption ] = useState(null);
	const [ isHovering, setIsHovering ] = useState(false);
	const [ defaultChoice ] = useState(choice.default === 'random' ? (Math.random() * 2) | 0 : choice.default);
	const [ orientation, setOrientation ] = useState(getOrientation(window.innerWidth, window.innerHeight));
	const [ t ] = useLocalisation();

	const timerProps = choice.timer
		? useSpring({
				to: { value: 1 },
				from: { value: 0 },
				config: { duration: choice.timer * 1000 },
				onFrame: (props) => {
					if (!hasChosenOnce.current && selectedOption === null && props.value > 0.8) {
						hasChosenOnce.current = true;
						setSelectedOption(defaultChoice);
					}
				},
				onRest: () => {
					setActivatedOption(selectedOption);
					activateOption(selectedOption);
				},
			})
		: null;

	const activateOption = (index) => {
		context.executor.execute(choice.options[index].action);
	};

	useEffect(() => {
		const preventDefault = (e) => e.preventDefault();
		window.addEventListener('touchmove', preventDefault, { passive: false });
		return () => {
			window.removeEventListener('touchmove', preventDefault, { passive: false });
		};
	}, []);

	useWindowSize(({ width, height }) => {
		setOrientation(getOrientation(width, height));
	});

	return (
		<div className="choice fill">
			<div className="choice__options">
				<Option
					text={t(choice.options[0].label)}
					isSelected={selectedOption === 0}
					isDiscarded={selectedOption !== 0 && selectedOption !== null}
					onSelect={(isSelected) => {
						setSelectedOption(isSelected ? 0 : null);
						setIsHovering(false);
						if (!choice.preventAutoContinue && isSelected) {
							activateOption(0);
						}
					}}
					onHover={(isHovering) => setIsHovering(isHovering)}
					direction={CHOICE_DIRECTIONS[orientation][0]}
				/>
				<div className={`choice__target ${isHovering ? 'choice__target--hover' : ''}`}>
					<div className="choice__question">{t(choice.question)}</div>
					{choice.timer ? (
						<div className="choice__timer">
							<AnimatedRadialProgress size={44} strokeWidth={8} progress={timerProps.value} />
						</div>
					) : (
						<div className="choice__instruction">{t('dilemma_help')}</div>
					)}
				</div>
				<Option
					text={t(choice.options[1].label)}
					isSelected={selectedOption === 1}
					isDiscarded={selectedOption !== 1 && selectedOption !== null}
					onSelect={(isSelected) => {
						setSelectedOption(isSelected ? 1 : null);
						setIsHovering(false);
						if (!choice.preventAutoContinue && isSelected) {
							activateOption(1);
						}
					}}
					onHover={(isHovering) => setIsHovering(isHovering)}
					direction={CHOICE_DIRECTIONS[orientation][1]}
				/>
			</div>
			<Header isVisible={true} isLight={true} />
		</div>
	);
};

const ChoiceWrapper = () => {
	const context = useContext(AppContext);

	const choiceTransitions = useTransition(context.showChoice, null, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
	});

	return choiceTransitions.map(
		({ item, key, props }) =>
			item && (
				<animated.div className="choice-wrapper fill" style={props} key={key}>
					<Choice />
				</animated.div>
			),
	);
};

export default ChoiceWrapper;
