import React, { useContext } from 'react';
import useSubtitle from '../../hooks/useSubtitle';

import './Subtitle.scss';
import useLocalisation from '../../content/localisation';
import AppContext from '../../model/ContextStore';

const Subtitle = ({ videoRef, name }) => {
	const [ , language ] = useLocalisation();
	const { showSubtitles } = useContext(AppContext);
	const subPath = `./subtitles/${name}_${language.toUpperCase()}.srt`;
	const text = useSubtitle(videoRef, subPath);
	return showSubtitles ? <div className="subtitle">{text}</div> : null;
};

export default Subtitle;
