import React, { useRef } from 'react';

function getVisibilityAttrs() {
	let hidden, visibilityChange;
	if (typeof document.hidden !== 'undefined') {
		// Opera 12.10 and Firefox 18 and later support
		hidden = 'hidden';
		visibilityChange = 'visibilitychange';
	} else if (typeof document.msHidden !== 'undefined') {
		hidden = 'msHidden';
		visibilityChange = 'msvisibilitychange';
	} else if (typeof document.webkitHidden !== 'undefined') {
		hidden = 'webkitHidden';
		visibilityChange = 'webkitvisibilitychange';
	}
	return { hidden, visibilityChange };
}

const useVisibility = (handleVisibilityChange) => {
	if (typeof document === 'undefined') {
		return;
	}
	let { hidden, visibilityChange } = getVisibilityAttrs();
	const wasVisible = useRef();

	function getVisible() {
		return !document[hidden];
	}

	function callback() {
		const isVisible = getVisible();
		if (isVisible !== wasVisible.current) {
			wasVisible.current = isVisible;
			handleVisibilityChange(isVisible);
		}
	}

	if (hidden === undefined || typeof document.addEventListener === 'undefined') {
		console.log(
			'This feature requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.',
		);
	} else {
		document.addEventListener(visibilityChange, callback);
	}

	callback();

	return () => {
		document.removeEventListener(visibilityChange, callback);
	};
};

export default useVisibility;
