import React, { useContext, useState, useRef, useEffect } from 'react';

import AppContext from '../../model/ContextStore';
import useLocalisation from '../../content/localisation';
import { STORIES_PER_ACTOR, STORIES_BY_ID } from '../../model/stories';

import './StoryChoice.scss';
import Tile from './Tile';
import Share from '../gui/Share';
import { useWindowSize } from '../../hooks/useOrientation';

export default () => {
	const { startStory } = useContext(AppContext);
	const [ t ] = useLocalisation();
	const [ selectedIndex, setSelectedIndex ] = useState(null);
	const [ activatedIndex, setActivatedIndex ] = useState(null);
	const actors = Object.keys(STORIES_PER_ACTOR);
	const actorVidRefs = actors.map((a) => useRef());
	const isStarted = useRef(false);

	const start = () => {
		isStarted.current = true;
		startStory(STORIES_BY_ID[STORIES_PER_ACTOR[actors[activatedIndex]][0]]);
	};

	const clickActor = (i) => {
		if (selectedIndex !== null) {
			actorVidRefs[selectedIndex].current.pause();
		}
		if (i !== selectedIndex) {
			if (i !== null) {
				const vid = actorVidRefs[i].current;
				vid.currentTime = 0;
				vid.play();
			}
			setSelectedIndex(i);
		} else if (selectedIndex !== null) {
			setActivatedIndex(selectedIndex);
		}
	};

	const [ sizeProp, setSizeProp ] = useState({});

	useWindowSize(({ width, height }) => {
        let size;
        if (width > height && width > 768) {
            size = (height - 80 - 56 - 56) / 2 - 50 - 8;
            setSizeProp({ width: size, height: size });
        } else {
            size =  width / 2 - 16 - 8;
            setSizeProp({ width: '100%', height: size });
        }
    });
    
	return (
		<div className="story-choice page">
			<div className="story-choice__subtitle" onClick={() => clickActor(null)}>
				{t('stories_subtitle')}
			</div>
			<div className="story-choice__stories">
				{actors.map((actor, i) => {
					const storyId = STORIES_PER_ACTOR[actor][0];
					const story = STORIES_BY_ID[storyId] || null;
					const props = {
						i,
						story,
						actorVidRef: actorVidRefs[i],
						activatedIndex,
						selectedIndex,
						clickActor,
						start,
						isStarted,
						sizeProp,
					};
					return i === 1 ? (
						<span key={i} >
							<Tile {...props} />
							<br />
						</span>
					) : (
						<Tile key={i} {...props} />
					);
				})}
			</div>
			<Share label={t('menu_share')} />
		</div>
	);
};
