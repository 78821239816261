import React, { useContext, useRef, useState, useEffect } from 'react';
import { VIDEO_PATH } from '../../model/constants';

import './Instructions.scss';
import useLocalisation from '../../content/localisation';
import ReactSVG from 'react-svg';
import { Link } from 'react-router5';
import Subtitle from '../gui/Subtitle';
import usePlayingState from '../../hooks/usePlayingState';
import usePlainVideo from '../../hooks/usePlainVideo';

const INTRO_ACTION = {
	type: 'clip',
	clip: 'app_intro/playlist.m3u8',
};

export default () => {
	const videoRef = useRef();
	const [ t ] = useLocalisation();

	const isPlaying = usePlayingState(videoRef);

    usePlainVideo(videoRef, INTRO_ACTION, null);

	return (
		<div className="instructions  page">
			<p className="instructions__intro">{t('landing_intro')}</p>
			<div
				className="instructions__teaser"
				onClick={() => {
					isPlaying ? videoRef.current.pause() : videoRef.current.play();
				}}
			>
				<video
                className="instructions__video"
                playsInline autoPlay loop muted ref={videoRef} />
				<Subtitle videoRef={videoRef} name="intro" />
				{!isPlaying && <ReactSVG className="instructions__play" src="./img/icon_play.svg" />}
				<div className="instructions__start">
					<Link
						routeName="warning"
						className="instructions__start-link bg"
						style={{
							backgroundImage: "url('./img/startknop.png')",
						}}
					>
						START
					</Link>
				</div>
			</div>
			<div className="instructions__logos">
				<ReactSVG className="instructions__logo" src="./img/logo-npostart.svg" />
				<ReactSVG className="instructions__logo instructions__logo--eo" src="./img/logo-eo.svg" />
				<ReactSVG className="instructions__logo" src="./img/logo-hsm.svg" />
			</div>
		</div>
	);
};
