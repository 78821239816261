import { STORIES_BY_ID } from "./stories";
import { getActionWithReflectionTriggers } from "./ContextStore";

export const ConditionChecker = function () {
    this.check = function (condition, state) {
        if (condition === true) {
            return true
        }
        if (condition === false) {
            return false
        }

        if (condition.type === 'checkvar') {
            let stored = state[condition.key];
            return stored === condition.value;
        }
    };
};

class Executor {

    constructor() {
        this.actions = {};
        this.state = null;
        this.conditionChecker = new ConditionChecker();
        this.router = null;
    }

    setState(state, dispatch, router) {
        this.state = state;
        this.dispatch = dispatch;
        this.router = router;
    }

    startStory(story) {
        this.execute({type:'startstory', story: story.id});
        // this.execute(story.startAction);
    }

    getActionById(id) {
        const a = this.actions[id];
        if (!a) {
            console.warn(`action with id ${id} not found`);
        }
        return a;
    }

    execute(actionOrId) {

        if (!actionOrId) {
            return false;
        }

        const action = (typeof actionOrId === 'string')
            ? this.getActionById(actionOrId)
            : actionOrId;

        if (action.condition !== undefined && !this.conditionChecker.check(action.condition, this.state)) {
            return false;
        }

        if (action.type === 'seek') {
            if (this.state.mainPlayer) {
                this.state.mainPlayer.currentTime = action.time;
			}
        }

        // if (action.type !== 'startstory') {
            this.dispatch(action);
        // }

        //* navigate action should occur AFTER the dispatch to avoid stuff being reset in dispatch
        if (action.type === 'startstory') {
            const story = STORIES_BY_ID[action.story];
            this.actions = story.actions;
            this.story = story;
            // console.log('Executor ->','START', action.story, story.startAction);
            this.router.navigate('chapter',{storyId: story.id, clipId: story.startAction});
            this.state.mainPlayer.play();
        }
        if (action.type === 'clip') {
            this.router.navigate('chapter',{storyId: this.story.id, clipId: action.id});
            if (this.state.mainPlayer) {
                this.state.updateClip(getActionWithReflectionTriggers(action, this.story.id));
                this.state.mainPlayer.currentTime = 0;
                this.state.mainPlayer.play();
            }
        }
        if (action.type === 'startstory') {
            this.execute(this.story.startAction);
        }
        if (action.type === 'finishstory') {
            this.router.navigate('stories');
        }
        if (['clip', 'choice', 'reflect'].indexOf(action.type) === -1) {
            this.execute(action.nextAction);
        }

        return true;
    }
}

export default Executor;