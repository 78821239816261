import React, { useRef, useState, useEffect } from 'react';
import fetch from 'isomorphic-fetch';
import * as Subtitle from 'subtitle/dist/subtitle.bundle.js';

const useSubtitle = (videoRef, filePath) => {
	const loadedPath = useRef();
	const [ isLoading, setIsLoading ] = useState(false);
	const [ currentText, setCurrentText ] = useState();
	const subs = useRef();

	if (filePath !== loadedPath.current) {
		loadedPath.current = filePath;
		setIsLoading(true);
		fetch(filePath).then((result) => result.text()).then((content) => {
			setIsLoading(false);
			subs.current = Subtitle.parse(content);
		});
	}

	useEffect(
		() => {
			const video = videoRef.current;
			const getSub = () => {
				const time = video.currentTime * 1000;
				const sub = subs.current.find((sub) => time >= sub.start && time < sub.end);
				const newText = sub ? sub.text : '';
				if (newText !== currentText) {
					setCurrentText(newText);
				}
			};
			if (video && subs.current) {
				video.addEventListener('timeupdate', getSub);
			}
			return () => {
				video.removeEventListener('timeupdate', getSub);
			};
		},
		[ videoRef.current, subs.current ],
	);

	return [ currentText, isLoading ];
};

export default useSubtitle;
