import React, { useContext, useState, useEffect } from 'react';
import { useTransition, animated } from 'react-spring/web.cjs';
import ReactSVG from 'react-svg';
import AppContext from '../../model/ContextStore';
import { REWIND_TIME, PAGES } from '../../model/constants';
import { STORIES_BY_ID, STORIES_PER_ACTOR } from '../../model/stories';
import useLocalisation from '../../content/localisation';

import './Pause.scss';
import Chain from './Chain';
import { useRouter } from 'react-router5';

const Pause = ({ isVisible, playerRef }) => {
	const router = useRouter();
    const [showEverything, setShowEverything] = useState(false);
	const { currentStoryId, setProps, startStory, pageAction, mainPlayer, showSubtitles } = useContext(AppContext);
	const [ t ] = useLocalisation();

	const transitions = useTransition(isVisible, null, {
		from: { opacity: 0, transform: 'translateY(-30%)' },
		enter: { opacity: 1, transform: 'translateY(0)' },
		leave: { opacity: 0, transform: 'translateY(20%)' },
    });
    
    useEffect(() => {
        if (isVisible) {
            setShowEverything(playerRef.current.currentTime > 1);
        }
    },[isVisible]);

	const story = STORIES_BY_ID[currentStoryId];
	const storiesTotal = STORIES_PER_ACTOR[story.actor].length;
	const currentStep = STORIES_PER_ACTOR[story.actor].indexOf(story.id);

	const rewind = () => {
		const currentTime = playerRef.current.currentTime;
		playerRef.current.currentTime = Math.max(0, currentTime - REWIND_TIME);
	};
	const forward = () => {
		const currentTime = playerRef.current.currentTime;
		playerRef.current.currentTime = currentTime + REWIND_TIME;
	};

	return transitions.map(
		({ item, key, props }) =>
			item && (
				<animated.div key={key} className="pause fill" style={{ opacity: props.opacity }}>
					<animated.div className="pause__center" style={{ transform: props.transform }}>
						<div className="pause__info">
							<div className="pause__actor">{story.actor}</div>
							<div className="pause__story">"{t(`story_${story.actor}`)}"</div>
							<div className="pause__narator">
								{t('pause_by')} {t('stories_'+story.narator)}
							</div>
						</div>
						{showEverything && (
							<div className="pause__rewind" onClick={rewind}>
								<ReactSVG className="pause__rewind-arrow" src="./img/icon_rewind.svg" />
								{REWIND_TIME}
							</div>
						)}
						<ReactSVG
							className="pause__play"
							src="./img/icon_play.svg"
							onClick={() => {
								mainPlayer.play();
							}}
						/>
						{showEverything && (
							<div className="pause__rewind" onClick={forward}>
								<ReactSVG className="pause__rewind-arrow" src="./img/icon_rewind.svg" style={{transform: 'scaleX(-1)'}}/>
								{REWIND_TIME}
							</div>
						)}
					</animated.div>
						<div
							className={`pause__subs ${showSubtitles ? 'pause__subs--active' : ''}`}
							onClick={() => setProps({ showSubtitles: !showSubtitles })}
						>
							<ReactSVG src="./img/icon_subs.svg" />
							<div className="pause__subs-label">{t('pause_sub')}<br/>{showSubtitles ? t('pause_sub_on'): t('pause_sub_off')}</div>
						</div>
					<div className={`pause__chain ${showEverything ? 'pause__chain--bottom' : ''}`}>
						<Chain
							currentStep={currentStep}
							stepProgress={
								(showEverything ? 0.2 : 0.1) +
								(currentStep === 0
									? 0.15 + 0.35 * (pageAction.progress || 0)
									: 0.5 * (pageAction.progress || 0))
							}
							stepsTotal={storiesTotal}
							onClickStep={(step) => {
								const stories = STORIES_PER_ACTOR[story.actor];
								startStory(STORIES_BY_ID[stories[step]]);
								mainPlayer.play();
							}}
						/>
					</div>
					{showEverything && (
						<button
							className="pause__other"
							style={{
								backgroundImage: `url(\'./img/otherstory.png\')`,
							}}
							onClick={() => router.navigate(PAGES.STORY_CHOICE.label)}
						>
							{t('pause_other')}
						</button>
					)}
				</animated.div>
			),
	);
};

export default Pause;
