import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app/App';
import fetch from 'isomorphic-fetch';
import createRouter from './routing/create-router';
import { RouterProvider } from 'react-router5';

const router = createRouter();

fetch('./texts.json').then((result) => result.json()).then((texts) => {
	router.start(() => {
		ReactDOM.hydrate(
			<RouterProvider router={router}>
				<App language="NL" texts={texts} />
			</RouterProvider>,
			document.getElementById('app-container'),
		);
	});
});
