import '@babel/polyfill';
import React from 'react';

import AppContent from './AppContent';
import { AppContextProvider } from '../../model/ContextStore';

import './App.scss';
import { LocalisationContextProvider } from '../../content/localisation';

const App = ({ language, texts }) => {
	return (
		<div className="app">
			<AppContextProvider>
				<LocalisationContextProvider initialLanguage={language} initialTexts={texts}>
					<AppContent />
				</LocalisationContextProvider>
			</AppContextProvider>
		</div>
	);
};

export default App;
