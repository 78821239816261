import React, {useContext} from 'react';

import './Share.scss';
import { SOCIAL_EVENT_LABEL } from '../../ejs-templates/constants';
import { STORIES_BY_ID } from '../../model/stories';
import AppContext from '../../model/ContextStore';
import useLocalisation from '../../content/localisation';

const Share = ({ label, url = 'https://geboeid.eo.nl/', text }) => {
    const encodedUrl = encodeURI(url);
    const appContext = useContext(AppContext);
    const {narator} = appContext.currentStoryId ? STORIES_BY_ID[appContext.currentStoryId] : '';
    const [t] = useLocalisation();
    const encodedText = encodeURI(text || t('share_content').replace('[]', t(`stories_${narator}`)));
	return (
		<div className="share">
			{label}
			<ul>
				<li>
					<a
						data-gtm-track-event-category="Social share"
						data-gtm-track-event-action="Facebook"
						data-gtm-track-event-label={SOCIAL_EVENT_LABEL}
						rel="noopener"
						target="_blank"
						href={`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`}
					>
						<img src="./img/facebook-icon.png" />
						<span>Facebook</span>
					</a>
				</li>
				<li>
					<a
						data-gtm-track-event-category="Social share"
						data-gtm-track-event-action="Twitter"
						data-gtm-track-event-label={SOCIAL_EVENT_LABEL}
						rel="noopener"
						target="_blank"
						href={`https://twitter.com/intent/tweet?url=${encodedUrl}&amp;text=${encodedText}&amp;via=Geboeid.nl`}
					>
						<img src="./img/twitter-icon.png" />
						<span>Twitter</span>
					</a>
				</li>
				<li className="share__mobile-only">
					<a
						data-gtm-track-event-category="Social share"
						data-gtm-track-event-action="WhatsApp"
						data-gtm-track-event-label={SOCIAL_EVENT_LABEL}
						rel="noopener"
						target="_blank"
						href={`whatsapp://send?text=${encodedText}%20${encodedUrl}`}
					>
						<img src="./img/whatsapp-icon.png" />
						<span>WhatsApp</span>
					</a>
				</li>
				<li>
					<a
						data-gtm-track-event-category="Social share"
						data-gtm-track-event-action="Email"
						data-gtm-track-event-label={SOCIAL_EVENT_LABEL}
						rel="noopener"
						target="_blank"
						href={`mailto:?${encodedText}`}
					>
						<img src="./img/mail-icon.png" />
						<span>E-mail</span>
					</a>
				</li>
			</ul>
		</div>
	);
};

export default Share;
